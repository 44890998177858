import { cn } from "~/utils/classnames";
import "./InputBase.css";
import type { InputBaseProps } from "./InputBase.types";

const InputBase = ({
  className,
  disabled,
  error,
  id,
  label,
  comment,
  children,
  required,
}: InputBaseProps) => (
  <div
    className={cn(
      "InputBase",
      error ? "InputBase--error" : null,
      disabled ? "InputBase--disabled" : null,
      className
    )}
  >
    {label || comment ? (
      <div className="flex items-center gap-2">
        {label ? (
          <label htmlFor={id} className="InputBase-label">
            {label}
            {required ? <span className="ml-1 text-red">*</span> : null}
          </label>
        ) : null}
        {comment ? <span className="InputBase-comment">{comment}</span> : null}
      </div>
    ) : null}

    {children}
    {error ? (
      <div className="text-sm font-bold text-red-error">{error}</div>
    ) : null}
  </div>
);

export default InputBase;
